import React, {useState, useCallback, useContext, useEffect} from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
	Button,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Table,
	TableHead,
	Paper,
	Tooltip,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import {
	Edit,
	SignalCellularConnectedNoInternet2Bar,
	SignalCellularConnectedNoInternet0Bar,
	SignalCellular4Bar,
	CropFree,
	DeleteOutline,
} from "@material-ui/icons";


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import MainContainer from "../../components/MainContainer";
import TableRowSkeleton from "../../components/TableRowSkeleton";


import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import ParametrosModal from "../../components/ConnectionModals/parametrosModal"
import ChatBotModal from "../../components/ConnectionModals/chatBotModal"
import VariaveisModal from "../../components/ConnectionModals/variaveisModal"
import TagTicketModal from "../../components/ConnectionModals/tagTicketModal"
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";
import {AuthContext} from "../../context/Auth/AuthContext";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import openSocket from "../../services/socket-io";

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(14),
		border: "1px solid #dadde9",
		maxWidth: 450,
	},
	tooltipPopper: {
		textAlign: "center",
	},
	buttonProgress: {
		color: green[500],
	},
}));

const CustomToolTip = ({ title, content, children }) => {
	const classes = useStyles();

	return (
		<Tooltip
			arrow
			classes={{
				tooltip: classes.tooltip,
				popper: classes.tooltipPopper,
			}}
			title={
				<React.Fragment>
					<Typography gutterBottom color="inherit">
						{title}
					</Typography>
					{content && <Typography>{content}</Typography>}
				</React.Fragment>
			}
		>
			{children}
		</Tooltip>
	);
};

const Connections = () => {
	const classes = useStyles();

	//const { whatsApps, loading } = useContext(WhatsAppsContext);
	const [qrCode, setQrCode] = useState('');
	const [whatsApps, setWhatsapp] = useState(null);
	const [whatsappSelected, setWhatsappselected] = useState(null);
	const [status, setStatus] = useState('PAIRING');
	const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
	const [modalVariaveis, setModalVariaveis] = useState(false);
	const [tagModal, setTagTicketModal] = useState(false);
	const [chatmodal, setChatbotmodal] = useState(false);
	const [parametrosOpen, setParametrosModalOpen] = useState(false);
	const [qrModalOpen, setQrModalOpen] = useState(false);
	const [qrModalEvolutionOpen, setqrModalEvolutionOpen] = useState(false);
	const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event, whatsApp) => {
		setSelectedWhatsApp(whatsApp)
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { user, tokenWhats } = useContext(AuthContext);

	const confirmationModalInitialState = {
		action: "",
		title: "",
		message: "",
		whatsAppId: "",
		open: false,
	};
	const [confirmModalInfo, setConfirmModalInfo] = useState(
		confirmationModalInitialState
	);

	useEffect(() => {

		const fetchSession = async () => {
			try {
				const { data } = await api.get("/whatsapp/"+user?.company_id);
				// console.log(data)
				data.map( async (w) => {
					// console.log(w)
					const res = await axios.get(`https://eu.wablas.com/api/device/info?token=${w.token}`);
					if (res.data){
						w.status = res.data.data.status;
						w.phone = res.data.data.sender;

						const whatsappData = {
							phone: res.data.data.sender,
							status: res.data.data.status
						}

						await api.put(`/whatsapp/${w.id}`, whatsappData);
					}
				})

				setTimeout(() => setWhatsapp(data),300)

				const socket = openSocket();
				socket.on("whatsappSession",async () => {
					const { data } = await api.get("/whatsapp/"+user?.company_id);
					setTimeout(() => setWhatsapp(data),300)
					
				});

			} catch (err) {

				toastError(err);
			}
		};
		fetchSession();
	}, []);

	const handleOpenWhatsAppModal = () => {
		setWhatsAppModalOpen(true);
	};

	const handleCloseWhatsAppModal = useCallback(() => {
		setWhatsAppModalOpen(false);
		setSelectedWhatsApp(null);
		window.location.reload()
	}, [setSelectedWhatsApp, setWhatsAppModalOpen]);

	const handleCloseParamentrosModal = useCallback(() => {
		setParametrosModalOpen(false);
		setChatbotmodal(false)
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setParametrosModalOpen]);

	const handleCloseTagModal = useCallback(() => {
		setTagTicketModal(false);
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setTagTicketModal]);

	const handleOpenQrModal = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setQrModalOpen(true);
	};

	const handleOpenQrModalEvolution = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setqrModalEvolutionOpen(true);
	};

	const handleCloseQrModal = useCallback(() => {
		setSelectedWhatsApp(null);
		setQrModalOpen(false);
	}, [setQrModalOpen, setSelectedWhatsApp]);

	const handleCloseQrModalEvolution = useCallback(() => {
		setSelectedWhatsApp(null);
		setqrModalEvolutionOpen(false);
	}, [setQrModalOpen, setSelectedWhatsApp]);

	const handleOpen = ( type) => {
		handleClose()

		if (type === 'geral'){
			handleOpenWhatsAppModal()
		}
		if (type === 'parametros'){
			setParametrosModalOpen(true)
		}
		if (type === 'variaveis'){
			setModalVariaveis(true)
		}
		if (type === 'tagTicket'){
			setTagTicketModal(true)
		}
		if (type === 'chatBot'){
			setChatbotmodal(true)
		}

	};


	const handleSubmitConfirmationModal = async () => {
		if (confirmModalInfo.action === "disconnect") {
			try {
				await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
			} catch (err) {
				toastError(err);
			}
		}

		if (confirmModalInfo.action === "delete") {
			try {
				await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
				toast.success(i18n.t("connections.toasts.deleted"));
			} catch (err) {
				toastError(err);
			}
		}

		setConfirmModalInfo(confirmationModalInitialState);
	};


	const renderStatusToolTips = whatsApp => {
		return (
			<div className={classes.customTableCell}>
				{whatsApp.toUpperCase() === "disconnected" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.disconnected.title")}
						content={i18n.t("connections.toolTips.disconnected.content")}
					>
						<SignalCellularConnectedNoInternet0Bar color="secondary" />
					</CustomToolTip>
				)}
				{whatsApp === "OPENING" && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
				{whatsApp === "qrcode" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.qrcode.title")}
						content={i18n.t("connections.toolTips.qrcode.content")}
					>
						<CropFree />
					</CustomToolTip>
				)}
				{whatsApp.toUpperCase() === "CONNECTED" && (
					<CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
						<SignalCellular4Bar style={{ color: green[500] }} />
					</CustomToolTip>
				)}
				{(whatsApp === "TIMEOUT" || whatsApp === "PAIRING") && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.timeout.title")}
						content={i18n.t("connections.toolTips.timeout.content")}
					>
						<SignalCellularConnectedNoInternet2Bar color="secondary" />
					</CustomToolTip>
				)}
			</div>
		);
	};

	// console.log(selectedWhatsApp)
	return (
		<MainContainer>
			<ConfirmationModal
				title={confirmModalInfo.title}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={handleSubmitConfirmationModal}
			>
				{confirmModalInfo.message}
			</ConfirmationModal>

			<ParametrosModal
				open={parametrosOpen}
				onClose={handleCloseParamentrosModal}
				whatsAppId={selectedWhatsApp?.id}
			/>

			<ChatBotModal
				open={chatmodal}
				onClose={handleCloseParamentrosModal}
				whatsAppId={selectedWhatsApp?.id}
			/>
			<TagTicketModal
				open={tagModal}
				onClose={handleCloseTagModal}
				whatsappId ={selectedWhatsApp?.id}
			/>
			<VariaveisModal
				open={modalVariaveis}
				onClose={() => setModalVariaveis(!modalVariaveis)}
			/>

			<WhatsAppModal
				open={whatsAppModalOpen}
				onClose={handleCloseWhatsAppModal}
				whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
			/>


			<Paper className={classes.mainPaper} variant="outlined">
				<Table size="small">
					<TableHead>
						<TableRow>

							<TableCell align="center">
								{i18n.t("connections.table.status")}
							</TableCell>
							<TableCell align="center">
								Nome Canal
							</TableCell>
							<TableCell align="center">
								{i18n.t("connections.table.session")}
							</TableCell>
							<TableCell align="center">
								Whatsapp
							</TableCell>
							<TableCell align="center">
							  Departamentos
							</TableCell>
							<TableCell align="center" >
								{i18n.t("connections.table.actions")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!whatsApps ? (
							<TableRowSkeleton />
						) : (
							<>
								{whatsApps &&
									whatsApps.map((whatsApp, index) => (
										<TableRow key={'index'+index.toString()}>
											<QrcodeModal
												link={`https://eu.wablas.com/api/device/scan?token=${whatsApp.token}`}
												open={qrModalOpen}
												onClose={handleCloseQrModal}
												whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
											/>
											<QrcodeModal
												link={qrCode}
												open={qrModalEvolutionOpen}
												onClose={handleCloseQrModalEvolution}
												whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
											/>

											<TableCell align="center">
												{renderStatusToolTips(whatsApp.status)}
												{(whatsApp.status === 'disconnected' || whatsApp.status === 'PAIRING') ? 'Desconectado' : 'Conectado'}
											</TableCell>
											<TableCell align="center">
												{whatsApp.name}
											</TableCell>
											<TableCell align="center">
												<a 
													style={{cursor:'pointer'}} 
													onClick={async () => {

														if(whatsApp.api == 'evolution'){
															try {
																let qrCode = await api.post(`/whatsapp/${whatsApp.company_id}/evolution/connect/${whatsApp.token}`);
												
																setQrCode(qrCode.data.base64)
																
															} catch (error) {
																
															}
															handleOpenQrModalEvolution(true)

														}else{
															handleOpenQrModal(true)
														}

													}}  
												>Scan QR Code</a>
											</TableCell>
											<TableCell align="center">
												{whatsApp.phone}
											</TableCell>
											<TableCell align="center">
												{
													whatsApp.queues && whatsApp.queues.map(q => (
														<div id={q.id} style={{display:'flex', flexDirection:'row',marginTop:5, flexWrap:'wrap', alignItems:'center', justifyContent:'center'}}>
															<Chip size="small" label={q.name} style={{backgroundColor: q.color, color:'#fff', marginRight:5 }} />
														</div>
													))
												}
											</TableCell>
											<TableCell align="center">

												<div>
													<IconButton
														aria-label="more"
														aria-controls="long-menu"
														aria-haspopup="true"
														onClick={(e) => handleClick(e, whatsApp)}
													>
														<MoreVertIcon />
													</IconButton>
													<Menu
														id="long-menu"
														anchorEl={anchorEl}
														keepMounted
														open={open}
														onClose={handleClose}
														PaperProps={{
															style: {
																maxHeight: 50 * 4.5,
																width: '300px',
															},
														}}
													>
													<MenuItem  onClick={() => handleOpen('geral')}>
														Nome do canal
													</MenuItem>
													<MenuItem  onClick={() => handleOpen('parametros')}>
														Paramêtros
													</MenuItem>
													<MenuItem  onClick={() => handleOpen('variaveis')}>
														Variáveis e formatação
													</MenuItem>
													<MenuItem  onClick={() => handleOpen('tagTicket')}>
														Etiqueta
													</MenuItem>
													<MenuItem  onClick={() => handleOpen('chatBot')}>
														Chat-bot
													</MenuItem>


													</Menu>
												</div>

											</TableCell>
										</TableRow>
									))}
							</>
						)}
					</TableBody>
				</Table>
			</Paper>
		</MainContainer>
	);
};

export default Connections;
