import React, {useContext, useEffect, useState, useCallback} from "react";
import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import useTickets from "../../hooks/useTickets";
import {format,  parseISO} from "date-fns";
import api from "../../services/api";
import {AuthContext} from "../../context/Auth/AuthContext";
import Avatar from "@material-ui/core/Avatar";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";

import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import toastError from "../../errors/toastError";
import style from "./styles.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;




const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    // // backgroundColor: "#eee",
    // padding: theme.spacing(4),
    height: `calc(100% - 48px)`,
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Relatorio = () => {
  const {user} = useContext(AuthContext);

  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(100);

  const [users, setUsers] = useState([]);
  const [date, setDate] = useState('');
  const [date1, setDate1] = useState('');
  const [operator, setOperator] = useState('Todos');
  const [status, setStatus] = useState('Todos');

  const [dataSet1, setDataset1] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showAll = true;

  const searchParam = '';
  const selectedQueueIds = [];

  const {count} = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    queueIds: JSON.stringify(selectedQueueIds),
  });


  useEffect(() => {
    async function getUsers() {
      const res = await api.get('/users', {
        params: {company_id: user.company_id}
      });
      setUsers(res.data.users);
    }

    getUsers()
  }, [])

  const handleSearch = async () => {

    if (!date && !date1){
      return toast.warning('Escolha as datas inicial e final!')
    }

    const result = await api.get("/ticketsRelatorio", {
      params: {
        status,
        operator,
        company_id : user.company_id,
        date,
        date1,
      },
    })

    var res = [];

    result.data.count > 0 && result.data.tickets.map(r => {


      if (r.value > 0){
        var total = r.value.toLocaleString('pt-br', {style: 'currency', currency:'BRL'})
      }else{
        var total = 'R$ 0,00'
      }

        res.push({
          name: r.contact.name,
          phone: r.contact.number,
          ticketId: r.id,
          canal: r.whatsapp.name,
          department: r.queue.name,
          operator: r.user.name,
          status: r.status === 'open' ? 'Aberto' : 'Fechado',
          origem: r.origem,
          negociacao: total,
          iniciado: format(parseISO(r.createdAt), "dd/MM/yyyy  HH:mm")
        })
    })

    setDataset1(res)
  }

  return (
      <div className={classes.chatContainer}>
        <MainHeaderButtonsWrapper>


          <div style={{width: '20%'}}>
            <TextField
                label={'Data Inicial'}
                style={{width: '100%'}}
                type="date"
                value={date}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div style={{width: '20%'}}>
            <TextField
                label={'Data Final'}
                style={{width: '100%'}}
                type="date"
                value={date1}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setDate1(e.target.value)}
            />
          </div>

          <div style={{width: '20%'}}>
            <Select
                style={{width: '100%'}}
                value={operator}
                onChange={(e) => setOperator(e.target.value)}
            >
              <MenuItem value={'Todos'}>Atendentes</MenuItem>
              {users && users.map((c, i) => (
                  <MenuItem key={i} value={c.id}>{c.name}</MenuItem>
              ))}

            </Select>

          </div>
          <div style={{width: '20%'}}>
            <Select
                style={{width: '100%'}}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={'Todos'}>Status</MenuItem>
              <MenuItem value={'open'}>Aberto</MenuItem>
              <MenuItem value={'close'}>Fechado</MenuItem>
            </Select>
          </div>

          {!dataSet1 ? (
              <div style={{width: '20%'}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSearch()}
                >
                  {'Gerar relatório'}
                </Button>
              </div>
          ) : null}

          {dataSet1 ? (
              <div style={{width: '25%'}}>
                <ExcelFile element={ <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setDataset1(null)}
                >
                  {'Baixar Relatório'}
                </Button>}>
                  <ExcelSheet data={dataSet1} name="Relatório">
                    <ExcelColumn label="Nome" value="name"  />
                    <ExcelColumn label="Telefone" value="phone"/>
                    <ExcelColumn label="Protocolo" value="ticketId"/>
                    <ExcelColumn label="Canal" value="canal"/>
                    <ExcelColumn label="Departamento" value="department"/>
                    <ExcelColumn label="Atendente" value={"operator"}/>
                    <ExcelColumn label="Status" value={"status"}/>
                    <ExcelColumn label="Origem" value={"origem"}/>
                    <ExcelColumn label="Negociação" value={"negociacao"}/>
                    <ExcelColumn label="Iniciado" value={"iniciado"}/>
                  </ExcelSheet>
                </ExcelFile>

              </div>
          ) : null}


        </MainHeaderButtonsWrapper>
      </div>
  );

}

export default Relatorio;
